import { useStore } from 'skid-composables'
import { computed } from 'vue'

export const useProductShow = (product, options = {}) => {
  const store = options.store || useStore()
  const images = computed(() =>
    store.getters['productImages/forProduct'](product.id)
  )
  const masterImages = computed(() =>
    images.value.filter((img) => img.variant_id == product.master.id)
  )
  const cover = computed(() => images.value.find((img) => img.is_cover))

  const tryToSetMasterCoverImage = (slideToImage) => {
    const targetImageId = (
      masterImages.value.find((img) => img.is_cover) ||
      masterImages.value[0] ||
      cover.value ||
      images.value[0]
    )?.id

    if (targetImageId) slideToImage(targetImageId)
  }
  return {
    tryToSetMasterCoverImage
  }
}

export default useProductShow
