import ProductSlideshow from '../../components/product/slideshow.vue'
import PromoterEventProductInfoCard from '../../components/promoter_event/info-card.vue'
import PromoterEventProductInfoCardMobile from '../../components/promoter_event/info-card-mobile.vue'
import ProductComments from '../../components/product/comments.vue'
import ProductDocumentList from '../../components/product/document-list.vue'
import DistributionBaseList from '../../components/distribution_base/list.vue'
import PromoterEventStorageService from '../../../../shared/services/promoter_event_storage_service.js'
import useProductShow from '@/shared/composables/use_product_show.js'

export default {
  name: 'promoter-event-product-show-page-container',

  components: {
    ProductSlideshow,
    PromoterEventProductInfoCard,
    PromoterEventProductInfoCardMobile,
    ProductComments,
    ProductDocumentList,
    DistributionBaseList
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      promoterEventId: null,
      promoterShareId: null,
      productId: null,
      isInitializing: true,
      promoterEventStorageService: null
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['products/isLoading']
    },

    promoterShare() {
      return this.$store.getters['promoterShares/find'](this.promoterShareId)
    },

    promoterEvent() {
      return (
        this.promoterShare &&
        this.$store.getters['promoterEvents/find'](this.promoterShare.event_id)
      )
    },

    product() {
      return this.$store.getters['products/find'](this.productId)
    },

    supportDocuments() {
      return this.$store.getters['productSupportDocuments/all']
    },

    distributionBases() {
      return this.$store.getters['distributionBases/all']
    },

    images() {
      return this.$store.getters['productImages/forProduct'](this.productId)
    },

    variants() {
      return this.$store.getters['productVariants/forProduct'](this.productId)
    },

    hasSupportDocuments() {
      return (
        !!this.product.support_document_detail ||
        this.supportDocuments.length > 0
      )
    }
  },

  // created() {},

  async mounted() {
    this.promoterEventId = this.$el.dataset.promoterEvent
    this.promoterShareId = this.$el.dataset.promoterShare
    this.productId = this.$el.dataset.product
    this.promoterEventStorageService = new PromoterEventStorageService(
      this.$vlf
    )

    await this.$store.dispatch(
      'promoterShares/findWithoutProducts',
      this.promoterShareId
    )

    await this.$store.dispatch(`promoterShares/fetchEventProductShips`, {
      model: this.promoterShare,
      options: {
        search: {
          product_id_eq: this.productId
        }
      }
    })

    await this.$store.dispatch('promoterEvents/findProduct', {
      model: this.promoterEvent,
      productId: this.productId
    })

    await this.promoterEventStorageService.savePromoterShareToken(
      this.promoterShare.token
    )

    await this.$store.dispatch('products/fetchSupportDocuments', this.product)

    this.isInitializing = false

    this.$nextTick(() => {
      this._addLazyLoading()
      useProductShow(this.product, {
        store: this.$store
      }).tryToSetMasterCoverImage(this.$refs.slider.slideToImage)
    })

    this.fetchShareProductShip()
  },

  methods: {
    _addLazyLoading() {
      lozad('.lozad', {
        load: (el) => {
          el.classList.add('loading', 'animated', 'fadeIn')
          el.src = el.dataset.src
          el.onload = function () {
            el.removeAttribute('data-src')
            el.classList.remove('loading')
            el.classList.add('loaded')
          }
        }
      }).observe()
    },

    variantChangedHandler(newVariant) {
      if (newVariant.images.length === 0) return

      const imageId = newVariant.images[0].id

      this.$refs.slider.slideToImage(imageId)
    },

    countdownEndedHandler() {
      this.$store.dispatch('products/find', this.productId)
    },

    onTabChange(targetValue) {
      let tabComponent = this.$refs.tabs,
        clickedTabLabel = tabComponent.childItems.find(
          (item) => item.value === targetValue
        ).label

      this.$store.dispatch('gaOperation', [
        ['send', 'event', 'UX', 'tabChange', clickedTabLabel]
      ])
    },

    fetchShareProductShip() {
      return this.$store.dispatch('promoterShares/fetchShareProductShips', {
        model: this.promoterShare,
        options: {
          search: {
            event_product_ship_product_id_eq: this.product.id
          }
        }
      })
    }
  }
}
